// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "TheNameIsTheFameOfTheGame-module--CarrouselWrapper2--aNGFF";
export var DescriptionWrapper = "TheNameIsTheFameOfTheGame-module--DescriptionWrapper--VbIDm";
export var DownloaderWrapper = "TheNameIsTheFameOfTheGame-module--DownloaderWrapper--NIH25";
export var EventWrapper = "TheNameIsTheFameOfTheGame-module--EventWrapper--vZ9yH";
export var ImageWrapper = "TheNameIsTheFameOfTheGame-module--ImageWrapper--B9-YH";
export var ImagesWrapper = "TheNameIsTheFameOfTheGame-module--ImagesWrapper--YgTWq";
export var MobileTile = "TheNameIsTheFameOfTheGame-module--MobileTile--gziPs";
export var PdpWrapper = "TheNameIsTheFameOfTheGame-module--PdpWrapper--ha4Mb";
export var PhotosWrapper = "TheNameIsTheFameOfTheGame-module--PhotosWrapper--Y5zFU";
export var TitleWrapper = "TheNameIsTheFameOfTheGame-module--TitleWrapper--F29Af";
export var Wrapper = "TheNameIsTheFameOfTheGame-module--Wrapper--dMNCQ";